<template>
  <div class="content">
    <div>Имя файла: {{ token?.fname ?? '' }}</div>
    <div>Загружено: {{ token ? Date(token.created_at).toLocaleString() : '' }}</div>
    <div>Всего строк: {{ token?.cnt ?? '' }}</div>
    <div>Обработано строк: {{ token?.resolved ?? '' }}</div>
    <div>Строк с ошибкой: {{ token?.errors?.length ?? 0 }}</div>
  </div>

  <Listbox v-if="token.errors?.length" :options="token.errors" listStyle="height:100%; flex: 1" scrollHeight="flex" />
</template>

<script setup>
import Listbox from 'primevue/listbox';
import { useStore } from 'vuex';

const store = useStore();
const token = store.state.tokenInfo;

// const kek = [];
// for (let i = 0; i < 50; i++) {
//   kek.push(...token.errors);
// }

// console.log('token info', token);
</script>

<style scoped lang="scss">
.content {
  padding: 4px 16px 16px;
}
</style>
<style lang="scss">
.p-listbox {
  display: flex;
  height: calc(100% - 160px);
}
</style>
