import { fitBbox, getRegionObject, getTownObject, INITIAL_BBOX } from '@/core/utils';
import { env } from '@/env/env';
import { httpService } from '@/services/HttpService';
import { projectsUpdate$, tableUpdate$ } from '@/services/UpdateHooks'; // сброс проекта и группы проектов происходит после projectsUpdate$.next()

export const mFetchRegions = (state) => {
  httpService.get(env.api.regions).then(({ data }) => {
    state.regions = data;
    state.regionsList = Object.keys(data)
      .map((id) => getRegionObject(id, state))
      .sort((first, last) => (first.name > last.name ? 1 : -1));
  });

  updateBbox(state);
};

export const mSetCurrentRegion = (state, id) => {
  if (state.currentRegion?.id === id) return;

  state.currentRegion = getRegionObject(id, state);
  state.currentTown = null;

  if (!state.towns[id]) {
    httpService.get(env.api.towns_id(id)).then(({ data }) => {
      state.towns[id] = { data };
      state.towns[id].list = Object.keys(data)
        .map((key) => getTownObject(id, key, state))
        .sort((first, last) => (first.name > last.name ? 1 : -1));
    });
  }

  updateBbox(state);
  tableUpdate$.next();
  projectsUpdate$.next();
};

export const mResetCurrentRegion = (state) => {
  state.currentRegion = null;
  state.currentTown = null;

  updateBbox(state);
  tableUpdate$.next();
  projectsUpdate$.next();
};

export const mSetCurrentTown = (state, id) => {
  state.currentTown = getTownObject(state.currentRegion.id, id, state);

  updateBbox(state);
  tableUpdate$.next();
  projectsUpdate$.next();
};

export const mResetCurrentTown = (state) => {
  state.currentTown = null;

  updateBbox(state);
  tableUpdate$.next();
  projectsUpdate$.next();
};

const updateBbox = (state) => {
  let request;

  if (state?.currentTown) {
    request = httpService.get(`${env.api.bbox_town}${state?.currentTown.id}/`);
  } else if (state?.currentRegion) {
    request = httpService.get(`${env.api.bbox_region}${state.currentRegion.id}/`);
  }

  if (!request) {
    return fitBbox(INITIAL_BBOX);
  }

  request.then(({ data }) => fitBbox(data));
};
