import { CUSTOM_WORKPLACE_STORAGE_KEY, fitBbox } from '@/core/utils';
import { env } from '@/env/env';
import { fileService } from '@/services/FileService';
import { httpService } from '@/services/HttpService';
import { mapService } from '@/services/MapService';
import { filter, of, Subject, switchMap, tap, timer } from 'rxjs';

export const mShowTokenInfo = (state, tokenInfo) => {
  state.tokenInfo = tokenInfo;
  fileService.openTokenModal();
};

export const mAddNewToken = (state, tokenInfo) => {
  state.tokens.push(tokenInfo);
  state.tokenInfo = tokenInfo;
  state.selectedToken = tokenInfo.token;

  saveTokens(state);

  mUseToken(state, tokenInfo);
};

export const mForgotToken = (state, token) => {
  state.tokens = state.tokens.filter((t) => t.token !== token);
  saveTokens(state);
};

export const mUseToken = (state, tokenInfo) => {
  const subject = new Subject();
  const getProgress = () =>
    timer(300).pipe(
      switchMap(() =>
        httpService.get(env.api.custom_workplaces_progress(tokenInfo.token)).catch((e) => {
          console.log('Ошибка в запросе, ответ пока не готов', e);
          return of({ data: { cnt: 0, resolved: 0, done: false } });
        })
      ),
      tap(({ data }) => {
        subject.next(data);
      })
    );

  // Самоподписывается, делает новый запрос после получения ответа
  subject
    .pipe(
      filter((data) => {
        state.workplacesLoading = {
          count: data.cnt,
          progress: data.resolved,
          errors: data.errors_count || 0,
        };

        const result = data.done && data.wps != undefined;
        if (!result) {
          getProgress().subscribe();
        }

        return result;
      })
    )
    .subscribe((data) => {
      state.workplacesLoading = null;
      const { wps, ...updatedToken } = data;

      const oldTokens = state.tokens.filter((t) => t.token !== tokenInfo.token);
      oldTokens.push(updatedToken);

      state.tokens = oldTokens;
      state.selectedToken = updatedToken.token;

      saveTokens(state);
      updateTableData(state, wps, true);

      if (updatedToken.errors?.length) {
        mShowTokenInfo(state, updatedToken);
      }
    });

  getProgress().subscribe();
};

export const mUpdateTableData = (state) => {
  if (!state.currentRegion && !state.currentTown) {
    clearWorkspaceData(state);
  }

  httpService
    .post('filters', env.api.workplaces, {
      region_id: state.currentRegion?.id,
      town_id: state.currentTown?.id,
    })
    .then(({ data }) => {
      updateTableData(state, data);
    })
    .catch(() => {
      clearWorkspaceData(state);
    });
};

const saveTokens = (state) => {
  localStorage.setItem(CUSTOM_WORKPLACE_STORAGE_KEY, JSON.stringify(state.tokens));
};

const clearWorkspaceData = (state) => {
  state.tableData = [];
  state.tableFields = [];
  mapService.updateWorkplaceSource([]);
  mapService.updateWorkplaceSource([], true);
};

const updateTableData = (state, data, isCustomWorkplaceSet = false) => {
  state.workplacesTableData = data;

  state.tableData = data.workplaces;
  state.tableFields = data.order;

  if (!isCustomWorkplaceSet) {
    state.selectedToken = null;
    mapService.updateWorkplaceSource(data.workplaces || []);
    mapService.updateWorkplaceSource([], true);
  }

  if (isCustomWorkplaceSet) {
    fitBbox(data.bbox);

    mapService.updateWorkplaceSource(data.workplaces || [], true);
  }
};
