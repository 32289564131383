const base = '/heatmap_backend/';

export const getEnv = ({ baseUrl }, dev = false) => ({
  dev,
  baseUrl,
  api: {
    get_filters: `${base}get_filters/`,
    get_legend: `${base}get_legend/`,
    get_hexes: `${base}get_hexes/`,
    get_heatmap_hexes: `${base}get_heatmap_hexes/`,

    regions: `${base}regions/`,
    towns: `${base}towns/`,
    towns_id: (id) => `${base}towns/${id}/`,

    workplaces: `${base}workplaces/`,
    workplaces_xlsx: `${base}workplaces/xlsx/`,
    workplaces_specs: `${base}workplaces/specs/`,

    template: `${base}static/template.xlsx`,

    custom_workplaces: `${base}workplaces/custom/`,
    custom_workplaces_progress: (token) => `${base}workplaces/custom/${token}/progress/`,
    custom_workplaces_xlsx: (token) => `${base}workplaces/custom/${token}/xlsx/`,
    custom_workplaces_errors_xlsx: (token) => `${base}workplaces/custom/${token}/errors/xlsx/`,

    groups_projects: `${base}groups_projects/data/`,
    projects: `${base}projects/`,
    projects_data_id: (id) => `${base}projects/${id}/data/`,

    bbox_town: `${base}town/bbox/`,
    bbox_region: `${base}region/bbox/`,

    dadata: `${base}dadata/suggest/`,
    deocode: `${base}dadata/geocode/`,

    professions: `${base}professions/`,

    etl: `${base}etl/last/`,
  },
});
